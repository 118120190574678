import Box, { boxProps } from 'humanity/primitives/box';

const Section = (props) => <Box as="section" width={1} {...props} />;

Section.displayName = 'Section';

Section.propTypes = {
  ...boxProps,
};

export default Section;
